var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-storage d-flex"},[_c('v-text-field',_vm._b({class:['flex-grow-1 mr-4', {
      'required-field': ![undefined, false].includes(_vm.$attrs.required),
    }],attrs:{"error-messages":_vm.errorMessages,"type":"number"},on:{"input":_vm.onInputValueChange,"blur":function($event){return _vm.$emit('blur')}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},'v-text-field',_vm.$attrs,false)),_c('v-select',{attrs:{"error":!_vm._.isEmpty(_vm.errorMessages),"items":_vm.$static.sizes,"menu-props":{
      bottom: true,
      offsetY: true,
      left: true,
      'min-width': '120px',
    }},on:{"input":function($event){return _vm.onInputValueChange(_vm.inputValue)},"blur":function($event){return _vm.$emit('blur')}},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }