<template>
  <div
    v-bind="$attrs"
    class="d-flex flex-nowrap space-x-6 align-center">
    <div class="key-input">
      <v-text-field
        ref="key"
        v-model="attribute.key"
        class="key-field required-field mb-4"
        :label="$t('forms.key')"
        :error-messages="inputErrors.key"
        required
        @blur="$v.attribute.key.$touch()"/>
    </div>

    <div class="value-input ml-6">
      <v-text-field
        ref="value"
        v-model="attribute.value"
        class="value-field required-field mb-4"
        :label="$t('forms.value')"
        :error-messages="inputErrors.value"
        required
        @blur="$v.attribute.value.$touch()"/>
    </div>

    <div class="ml-6">
      <CyButton
        theme="error"
        variant="secondary"
        icon="delete"
        icon-only
        sm
        @click="$emit('remove')"/>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CyResourceCustomAttributeInput',
  props: {
    pair: {
      type: Object,
      required: true,
    },
  },
  validations () {
    return {
      attribute: {
        key: { required },
        value: { required },
      },
    }
  },
  computed: {
    attribute: {
      set (pair) { this.$emit('input', pair) },
      get () { return this.pair || {} },
    },
    inputErrors () {
      const inputs = ['key', 'value']
      return inputs.reduce((acc, inputType) => {
        const errors = []
        const { $dirty, required } = this.$v.attribute[inputType]
        if (!$dirty) return { ...acc, [inputType]: [] }
        if (!required) errors.push(this.$t('forms.fieldRequired'))
        return { ...acc, [inputType]: errors }
      }, {})
    },
  },
}
</script>

<style lang="scss" scoped>
.key-input {
  flex: 1 0 20%;
}

.value-input {
  flex: 1 0 35%;
}
</style>
