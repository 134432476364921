<template>
  <div class="input-storage d-flex">
    <v-text-field
      v-bind="$attrs"
      v-model="inputValue"
      :class="['flex-grow-1 mr-4', {
        'required-field': ![undefined, false].includes($attrs.required),
      }]"
      :error-messages="errorMessages"
      type="number"
      @input="onInputValueChange"
      @blur="$emit('blur')"/>
    <v-select
      v-model="size"
      :error="!_.isEmpty(errorMessages)"
      :items="$static.sizes"
      :menu-props="{
        bottom: true,
        offsetY: true,
        left: true,
        'min-width': '120px',
      }"
      @input="onInputValueChange(inputValue)"
      @blur="$emit('blur')"/>
  </div>
</template>

<script>
import { K_BYTES, formatBytes } from '@/utils/helpers/quotas'

export default {
  name: 'CyInputsStorage',
  props: {
    value: {
      type: Number,
      default: null,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    size: 'GB',
    inputValue: null,
    initialValue: null,
  }),
  computed: {
    $static: () => ({
      sizes: ['MB', 'GB', 'TB'],
    }),
  },
  watch: {
    value (newValue) {
      if (this.inputValue === null || newValue === this.initialValue) {
        this.setInputsFromValue(newValue)
      }
    },
  },
  created () {
    this.setInputsFromValue(this.value)
  },
  methods: {
    onInputValueChange (value) {
      if (value === '') {
        this.$emit('input', null)
        return
      }
      const sizeIndex = this.$static.sizes.indexOf(this.size)
      const sizeMultiplier = Math.pow(K_BYTES, sizeIndex)
      const sizedOutput = Number(value) * sizeMultiplier

      this.$emit('input', _.round(sizedOutput))
    },
    getRoundedBytes ({ count, size }) {
      if (size === 'MB') return [_.round(count), size]
      if (count === _.round(count, 3)) return [count, size]
      const sizeIndex = this.$static.sizes.indexOf(size)
      const nextSmallestSize = this.$static.sizes[sizeIndex - 1]
      return this.getRoundedBytes({ count: count * K_BYTES, size: nextSmallestSize })
    },
    setInputsFromValue (value) {
      if (value === null) {
        this.size = 'GB'
        this.inputValue = null
        return
      }

      this.initialValue ||= value

      const { count, size } = formatBytes(value, { format: 'object', round: false })
      const [roundedCount, roundedSize] = this.getRoundedBytes({ count, size })
      this.size = roundedSize
      this.inputValue = roundedCount
    },
  },
}
</script>

<style lang="scss" scoped>
.input-storage {
  max-width: 520px;

  .v-select {
    max-width: 64px;
  }
}
</style>
